<script setup>
import LoadingSpinner from "@/assets/img/loading_spinning.svg"
import CFLogo from "@/assets/img/brand_logo_pc.png"
import { onMounted, ref } from "vue";
import { timestampParse, fetchApi } from '@/utils'
import { useEventStore } from "@/store/event";
import moment from "moment"
import { useRoute } from "vue-router";

const Route = useRoute()
const loading = ref(true)
const EventStore = useEventStore()
onMounted(async () => {
  if (Route.query.filter) changeShowEventState(Route.query.filter)
  await EventStore.queryEventList()
  const timeout = setTimeout(() => {
    loading.value = false
  }, 300)
})

const showEventState = ref("active")
function changeShowEventState(state) {
  showEventState.value = state
}
</script>

<template>
  <Transition name="sample-fade-in">
  <div v-if="loading" class="view-event-loading">
    <div class="loading-wrapper">
      <img class="spinner" :src="LoadingSpinner">
      <img class="logo" :src="CFLogo">
    </div>
  </div>
  <div v-else class="view-event-container">
    <header>
      <h1>優惠活動</h1>
      <hr>
      <ul>
        <li :class="{ 'active': showEventState === 'active' }" @click="changeShowEventState('active')">進行中的活動</li>
        <li :class="{ 'active': showEventState === 'past' }" @click="changeShowEventState('past')">已結束的活動</li>
      </ul>
    </header>

    <main>
      <section>
        <ul>
          <li
            v-for="event in showEventState === 'active' ? EventStore.noPastEvents : EventStore.pastEvents"
            :key="`event-${event.event_id}`"
            :class="{ [event.event_state]: true }"
          >
            <router-link :to="`/event/${event.event_url}`">
              <div :class="{ 'img-wrapper': true }">
                <img :src="`/img${event.event_banner}`" alt="">
              </div>
              <div class="content">
                <h2>{{ event.event_name }}</h2>
                <p>{{ event.event_summary }}</p>
                <hr>
                <span>
                  <p>{{ timestampParse(event.event_date_start).format('YYYY.MM.DD') }} ~ {{ timestampParse(event.event_date_end).format('MM.DD') }}</p>
                  <div class="badge" v-if="event.event_state !== 'past'">
                    還剩 
                    {{ event.event_state === 'active'
                      ? moment().diff(timestampParse(event.event_date_end), 'd') * -1 + 1
                      :  moment().diff(timestampParse(event.event_date_start), 'd') * -1 + 1
                    }} 天 {{ event.event_state === 'active' ? '結束' : '開始' }}
                  </div>
                </span>
              </div>
            </router-link>
          </li>
        </ul>
      </section>
    </main>
  </div>
  </Transition>
</template>
